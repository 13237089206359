import loadable from '@loadable/component';

import BpkBreadcrumb, {
  BpkBreadcrumbItem,
} from '@skyscanner/backpack-web/bpk-component-breadcrumb';

import { ACTION_TYPE, COMPONENT_ACTION, COMPONENT_NAME } from '../../constants';
import logMiniEventHelper from '../../mini-event/logMiniEventHelper';
import withErrorBoundary from '../ErrorBoundary';

import type {
  BreadcrumbsProps,
  BreadcrumbsItemLink,
} from '@skyscanner-internal/falcon-shared-types/types/components';

import STYLES from './Breadcrumbs.module.scss';

const IntersectionObserverWrapper = loadable(
  () => import('../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);

const onBreadcrumbClick = (e: Event) => {
  const target = e.target as HTMLLinkElement;
  if (target.href) {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_CLICKED,
      component_name: COMPONENT_NAME.BREADCRUMBS,
      component_action: COMPONENT_ACTION.BREADCRUMBS.BREADCRUMBS_CLICKED,
      link_info: {
        url: target.href,
        content: target.textContent,
      },
    });
  }
};

const Breadcrumbs = ({ breadcrumbs: entries, metadata }: BreadcrumbsProps) => {
  const createBpkBreadcrumbItem = (
    { label, page_type_id: pageTypeId, url }: BreadcrumbsItemLink,
    index: number,
    array: BreadcrumbsItemLink[],
  ) => {
    if (index === array.length - 1) {
      return (
        <BpkBreadcrumbItem key={pageTypeId} active>
          {label}
        </BpkBreadcrumbItem>
      );
    }
    return (
      <BpkBreadcrumbItem key={pageTypeId} href={url}>
        {label}
      </BpkBreadcrumbItem>
    );
  };

  let links: JSX.Element[] | string = '';
  if (Array.isArray(entries)) links = entries.map(createBpkBreadcrumbItem);

  const schema = { __html: metadata };

  /* eslint-disable react/no-danger */
  return (
    <div id="breadcrumbs-root" className={STYLES.Breadcrumbs}>
      <script type="application/ld+json" dangerouslySetInnerHTML={schema} />
      <BpkBreadcrumb
        label="breadcrumb"
        onClick={(e: Event) => onBreadcrumbClick(e)}
      >
        {links}
      </BpkBreadcrumb>
    </div>
  );
  /* eslint-enable react/no-danger */
};

const BreadcrumbsWrapper = (props: BreadcrumbsProps) => (
  <IntersectionObserverWrapper
    onElementSeen={() =>
      logMiniEventHelper({
        action_type: ACTION_TYPE.COMPONENT_VISIBLE,
        component_name: COMPONENT_NAME.BREADCRUMBS,
      })
    }
  >
    <Breadcrumbs {...props} />
  </IntersectionObserverWrapper>
);

const BreadcrumbsWithErrorBoundary = withErrorBoundary(
  BreadcrumbsWrapper,
  'breadcrumbs-component',
);

export default BreadcrumbsWithErrorBoundary;
